// app/javascript/controllers/mfa_success_controller.js
import { Controller } from "@hotwired/stimulus"
import { CheckmarkAnimation } from "@/helpers/checkmark_animation"
import gsap from "gsap"
import Flip from "gsap/Flip";
gsap.registerPlugin(Flip)

export default class extends Controller {
  static targets = [
    "checkmark",
    "statusText",
    "contentContainer"
  ]

  connect() {
    // Initialize checkmark animation
    this.checkmark = new CheckmarkAnimation(this.checkmarkTarget, {
      duration: 1,
      autoplay: false,
    })
    gsap.set(this.statusTextTarget, {
      opacity: 0
    })
    gsap.set(this.contentContainerTarget, {
      opacity: 0
    })
    gsap.to(this.statusTextTarget, {
      opacity: 1,
      duration: 0.6,
      ease: "power2.out"
    })
    gsap.to(this.contentContainerTarget, {
      opacity: 1,
      duration: 0.6,
      ease: "power2.out"
    })
    this.checkmark.play()
  }

  animateStatusText() {
    // Get the FLIP state before changes
    const state = Flip.getState(this.element)

    // Remove hidden class and get natural width
    this.statusTextTarget.classList.remove('hidden')
    this.statusTextTarget.style.width = 0

    // Create the width reveal animation
    const tl = gsap.timeline()

    tl.to(this.statusTextTarget, {
      width: 'auto',
      duration: 0.6,
      ease: "power2.inOut"
    })

    // Use FLIP to animate any layout changes
    Flip.from(state, {
      duration: 0.6,
      ease: "power2.inOut",
      absolute: true,
      onComplete: () => {
        // Clean up inline styles after animation
        gsap.set(this.statusTextTarget, {
          clearProps: "width"
        })
      }
    })
  }

  animateContentContainer() {
    const state = Flip.getState(this.element)

    // Show content container
    this.contentContainerTarget.classList.remove('hidden')

    // Animate from previous state
    Flip.from(state, {
      duration: 0.8,
      ease: "power2.inOut",
      absolute: true,
      onComplete: () => {
        // Optional: Add any cleanup or next steps here
      }
    })

    // Fade in content
    gsap.fromTo(this.contentContainerTarget,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.6,
        ease: "power2.out",
        delay: 0.2
      }
    )
  }
}
