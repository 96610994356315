import { Controller } from "stimulus";
import { gsap } from "gsap";

export default class extends Controller {
  static targets = ["item", "underline"];
  static values = { noUnderline: Boolean };

  connect() {
    if (!this.noUnderlineValue) {
      // Set initial position
      const activeItem = this.itemTargets.find(item => item.dataset.active === "true")
      if (activeItem) {
        // Update active item styles immediately
        this.updateItemStyles(activeItem)

        // Position the underline without animation first
        const offset = activeItem.offsetLeft
        const width = activeItem.offsetWidth

        // Set initial position with opacity 0
        gsap.set(this.underlineTarget, {
          x: offset,
          width: width,
          opacity: 0
        })

        // Fade in the underline
        gsap.to(this.underlineTarget, {
          opacity: 1,
          duration: 0.1,
          ease: "power2.out",
          delay: 0.2
        })
      }
    }

    // Add click handlers
    this.itemTargets.forEach(item => {
      item.addEventListener('click', () => {
        this.setActiveItem(item)
      })
    })
  }

  setActiveItem(clickedItem) {
    // Update active states
    this.itemTargets.forEach(item => {
      item.dataset.active = "false"
      item.classList.remove("text-foreground", "font-medium", "border-white")
      item.classList.add("text-gray-300", "border-transparent")
    })

    // Update clicked item
    clickedItem.dataset.active = "true"
    this.updateItemStyles(clickedItem)

    // Update underline if enabled
    if (!this.noUnderlineValue) {
      this.moveUnderline(clickedItem)
    }
  }

  updateItemStyles(item) {
    item.classList.remove("text-gray-300")
    item.classList.add("text-foreground", "font-medium")
  }

  moveUnderline(targetItem) {
    const newOffset = targetItem.offsetLeft
    const newWidth = targetItem.offsetWidth
    const currentOffset = parseFloat(getComputedStyle(this.underlineTarget).transform.split(',')[4]) || 0
    const currentWidth = this.underlineTarget.offsetWidth

    // Calculate the midpoint for the width transition
    const distance = Math.abs(newOffset - currentOffset)
    const midpoint = Math.min(currentWidth, newWidth)

    // Create a timeline for smooth animation
    const tl = gsap.timeline({
      defaults: {
        duration: 0.3,
        ease: "power2.inOut"
      }
    })

    if (newOffset > currentOffset) {
      // Moving right: shrink from right side, move, then expand from left side
      tl.to(this.underlineTarget, {
        width: midpoint,
        duration: 0.15
      })
        .to(this.underlineTarget, {
          x: newOffset,
          duration: 0.15
        }, "-=0.1")
        .to(this.underlineTarget, {
          width: newWidth,
          duration: 0.15
        }, "-=0.1")
    } else {
      // Moving left: shrink from left side, move, then expand from right side
      tl.to(this.underlineTarget, {
        width: midpoint,
        x: newOffset + (newWidth - midpoint),
        duration: 0.15
      })
        .to(this.underlineTarget, {
          x: newOffset,
          duration: 0.15
        }, "-=0.1")
        .to(this.underlineTarget, {
          width: newWidth,
          duration: 0.15
        }, "-=0.1")
    }
  }

  hideUnderline() {
    gsap.set(this.underlineTarget, { opacity: 0 })
  }
}
