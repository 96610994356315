import { Controller } from 'stimulus';
import { showElement, hideElement } from '~/helpers/animations_helpers';

export default class extends Controller {
  static targets = ['chevron', 'hidden'];

  isVisible = false;

  connect() {
    // Initialize the chevron rotation on load
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transition = 'transform 0.3s ease';
    }
  }

  async toggle() {
    if (this.isVisible) {
      await this.hide();
    } else {
      await this.show();
    }

    this.isVisible = !this.isVisible;
  }

  async show() {
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transform = 'rotate(180deg)';
    }

    if (this.hasHiddenTarget) {
      await showElement(this.hiddenTarget);
    }
  }

  async hide() {
    if (this.hasChevronTarget) {
      this.chevronTarget.style.transform = 'rotate(0deg)';
    }

    if (this.hasHiddenTarget) {
      await hideElement(this.hiddenTarget);
    }
  }
}
