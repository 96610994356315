import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["code"]
  static values = {
    filename: { type: String, default: "backup-codes.txt" }
  }

  download(event) {
    event.preventDefault()

    // Get all codes from the page
    const codes = this.codeTargets.map(element => element.textContent.trim())

    // Format codes with a header and instructions
    const content = [
      "2FA Backup Codes",
      "================",
      "",
      "Store these backup codes in a safe place. Each code can only be used once.",
      "",
      ...codes,
      "",
      "Generated on: " + new Date().toLocaleDateString()
    ].join("\n")

    // Create blob and download link
    const blob = new Blob([content], { type: "text/plain" })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")

    link.href = url
    link.download = this.filenameValue
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
}
