import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    maxItems: { type: Number, default: 100 },
  };

  connect() {
    this.pruneList();
  }

  pruneList() {
    const items = this.element.children;
    if (items.length > this.maxItemsValue) {
      for (let i = items.length - 1; i >= this.maxItemsValue; i--) {
        items[i].remove();
      }
    }
  }
}
