// signup_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email", "password", "checkbox", "button", "passwordError", "successIcon", "errorIcon"]

  connect() {
    this.validateForm()
  }

  validateForm() {
    const isEmailValid = this.validateEmail()
    const isPasswordValid = this.validatePassword()
    const isCheckboxChecked = this.checkboxTarget.checked

    const isFormValid = isEmailValid && isPasswordValid && isCheckboxChecked
    this.buttonTarget.disabled = !isFormValid

    // Update password validation UI
    if (isPasswordValid) {
      this.successIconTarget.classList.remove('hidden')
      this.errorIconTarget.classList.add('hidden')
      this.passwordErrorTarget.classList.remove('text-error-medium')
      this.passwordErrorTarget.classList.add('text-success')
    } else {
      this.successIconTarget.classList.add('hidden')
      this.errorIconTarget.classList.remove('hidden')
      this.passwordErrorTarget.classList.add('text-error-medium')
      this.passwordErrorTarget.classList.remove('text-success')
    }
  }

  validateEmail() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(this.emailTarget.value)
  }

  validatePassword() {
    return this.passwordTarget.value.length >= 8
  }

  handleSubmit(event) {
    event.preventDefault()

    if (!this.buttonTarget.disabled) {
      const redirectUrl = this.buttonTarget.dataset.url
      window.location.href = redirectUrl
    }
  }
}
