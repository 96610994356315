import { Controller } from "stimulus"
import { hideElement, showElement } from '~/helpers/animations_helpers';

export default class extends Controller {
  static targets = ["panel", "svg"]

  connect() {
    // Bind the clickOutside method to maintain proper 'this' context
    this.clickOutsideHandler = this.clickOutside.bind(this)
    // Add global click listener
    document.addEventListener('click', this.clickOutsideHandler)
  }

  disconnect() {
    // Clean up the global click listener when controller is disconnected
    document.removeEventListener('click', this.clickOutsideHandler)
  }

  togglePanel(event) {
    event.stopPropagation()

    if (this.panelTarget.classList.contains('hidden')) {
      this.openPanel()
    } else {
      this.closePanel()
    }
  }

  openPanel() {
    showElement(this.panelTarget, 0.15)
    this.svgTarget.classList.remove('text-gray-400')
    this.svgTarget.classList.add('text-white')
  }

  closePanel() {
    hideElement(this.panelTarget, 0.15)
    this.svgTarget.classList.remove('text-white')
    this.svgTarget.classList.add('text-gray-400')
  }

  clickOutside(event) {
    // Check if click was outside the panel and button
    if (!this.panelTarget.classList.contains('hidden') &&
      !this.element.contains(event.target)) {
      this.closePanel()
    }
  }
}
