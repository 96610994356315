import gsap from 'gsap';

// When a new animation is added in tailwind.config.js, it must be added here as well.
type ANIMATION_TYPES = 'fade' | 'modal' | 'accordion';
type style = 'hidden' | 'height';

// Blog explanation on how this works if interested https://sebastiandedeyne.com/javascript-framework-diet-enter-leave-transitions
export async function animate(
  element: HTMLElement,
  type: 'enter' | 'leave',
  transition: ANIMATION_TYPES,
  style: style = 'hidden'
) {
  if (type === 'enter') {
    element.classList.remove('hidden');
    if (style === 'height') {
      element.classList.remove('h-0');
    }
  }

  element.classList.add(`${transition}-${type}`);
  element.classList.add(`${transition}-${type}-start`);

  await nextFrame();

  element.classList.remove(`${transition}-${type}-start`);
  element.classList.add(`${transition}-${type}-active`);

  await afterTransition(element);

  element.classList.remove(`${transition}-${type}-active`);
  element.classList.remove(`${transition}-${type}`);

  if (type === 'leave') {
    element.classList.add('hidden');
    if (style === 'height') {
      element.classList.add('h-0');
    }
  }
}

function nextFrame() {
  return new Promise((resolve) => {
    requestAnimationFrame(resolve);
  });
}

function afterTransition(element: HTMLElement) {
  return new Promise((resolve) => {
    const computedStyle = getComputedStyle(element);
    const transitionDuration = computedStyle.transitionDuration;
    const durationValue = parseFloat(transitionDuration);
    const durationUnit = transitionDuration.replace(
      durationValue.toString(),
      ''
    );
    const durationInMs =
      durationUnit === 'ms' ? durationValue : durationValue * 1000;

    setTimeout(() => {
      resolve(true);
    }, durationInMs);
  });
}

export function getHiddenHeight(el: any) {
  if (!el?.cloneNode) {
    return null;
  }

  const clone = el.cloneNode(true);

  Object.assign(clone.style, {
    overflow: 'visible',
    height: 'auto',
    maxHeight: 'none',
    opacity: '0',
    visibility: 'hidden',
    display: 'block',
  });

  el.after(clone);
  const height = clone.offsetHeight;

  clone.remove();

  return height;
}
/**
 * Helper function to show an element that you are unsure if it is hidden or not
 * @param element
 * @param duration
 */
export async function showElement(element: HTMLElement, duration = 0.3): Promise<void> {
  if (element.classList.contains('hidden')) {
    await toggleVisibility(element, duration);
  }
}

/**
 * Helper function to hide an element that you are unsure if it is hidden or not
 * @param element
 * @param duration
 */
export async function hideElement(element: HTMLElement, duration = 0.3): Promise<void> {
  if (!element.classList.contains('hidden')) {
    await toggleVisibility(element, duration);
  }
}

/**
 * Helper function to toggle visibility of an element that takes into consideration styles
 * and correctly animates the element in and out of the dom without layout shifts
 * @param element
 * @param duration
 */
export async function toggleVisibility(element: HTMLElement, duration = 0.3): Promise<void> {
  return new Promise((resolve) => {
    if (!element.dataset.originalStyles) {
      element.dataset.originalStyles = JSON.stringify({
        paddingTop: element.style.paddingTop,
        paddingBottom: element.style.paddingBottom,
        marginTop: element.style.marginTop,
        marginBottom: element.style.marginBottom,
      });
    }

    if (element.classList.contains('hidden')) {
      // Show element
      element.classList.remove('hidden');
      element.style.visibility = 'visible';
      element.style.overflow = 'hidden';

      const originalStyles = JSON.parse(element.dataset.originalStyles!);

      // Important: Set these styles before measuring height
      element.style.paddingTop = originalStyles.paddingTop;
      element.style.paddingBottom = originalStyles.paddingBottom;
      element.style.marginTop = originalStyles.marginTop;
      element.style.marginBottom = originalStyles.marginBottom;

      // Measure final height with padding/margins restored
      element.style.height = 'auto';
      const autoHeight = element.offsetHeight;
      element.style.height = '0';

      // Single animation without changing other properties
      gsap.to(element, {
        duration: duration,
        height: autoHeight,
        opacity: 1,
        ease: 'power2.out',
        onComplete: () => {
          // Remove fixed height only after ensuring content is stable
          requestAnimationFrame(() => {
            element.style.height = 'auto';
            element.style.overflow = 'visible';
          });
          resolve();
        },
      });
    } else {
      // Hide element - start from current height
      const currentHeight = element.offsetHeight;
      element.style.height = `${currentHeight}px`;
      element.style.overflow = 'hidden';

      gsap.to(element, {
        duration: duration,
        height: 0,
        opacity: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0,
        ease: 'power2.in',
        onComplete: () => {
          element.style.visibility = 'hidden';
          element.classList.add('hidden');
          resolve();
        },
      });
    }
  });
}
