import { Controller } from '@hotwired/stimulus';
import gsap from 'gsap';

export default class extends Controller {
  connect() {
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const uuidPattern = /pre_market_table_row_\d+/;
    const { action, target } = event.detail.newStream;

    if (action === 'replace' && uuidPattern.test(target)) {
      event.preventDefault();
      this.handleRowUpdate(target, event.detail.newStream);
    }
  }

  handleRowUpdate(rowId, newStream) {
    const currentRow = document.getElementById(rowId);
    const templateContent = newStream.querySelector('template').content;
    const newRow = templateContent.querySelector(`#${rowId}`);

    const currentPrice = parseFloat(currentRow.querySelector('[data-current-price]').dataset.currentPrice);
    const newPrice = parseFloat(newRow.querySelector('[data-current-price]').dataset.currentPrice);

    console.log('Price comparison:', { currentPrice, newPrice });

    const priceIncreased = newPrice > currentPrice;
    console.log('Price increased?', priceIncreased);

    // Update content immediately
    currentRow.innerHTML = newRow.innerHTML;

    // Animate background
    this.animateBackground(currentRow, priceIncreased);
  }

  animateBackground(row, priceIncreased) {
    const baseColor = '#10191A';
    const increaseGradient = 'linear-gradient(90deg, rgba(21, 96, 74, 0.19) 87.07%, rgba(16, 25, 26, 0.19) 100%)';
    const decreaseGradient = 'linear-gradient(90deg, rgba(96, 21, 21, 0.19) 87.07%, rgba(16, 25, 26, 0.19) 100%)';

    console.log('Animating with gradient:', priceIncreased ? 'increase' : 'decrease');

    return gsap.timeline()
      .fromTo(row,
        { background: baseColor },
        {
          background: priceIncreased ? increaseGradient : decreaseGradient,
          duration: 0.5,
          ease: 'none',
        }
      )
      .to(row, {
        background: baseColor,
        duration: 0.5,
        ease: 'none',
      });
  }
}
