import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "thumb", "step", "tooltip"]

  connect() {
    this.updateSlider()
  }

  updateSlider() {
    const value = this.inputTarget.value
    const percent = (value / this.inputTarget.max) * 100

    this.thumbTarget.style.width = `${percent}%`
    this.tooltipTarget.textContent = `${value}%`

    this.stepTargets.forEach((step, index) => {
      const stepPercent = (index / (this.stepTargets.length - 1)) * 100
      if (percent >= stepPercent) {
        step.firstElementChild.classList.remove("bg-stroke")
        step.firstElementChild.classList.add("bg-trade-primary")
      } else {
        step.firstElementChild.classList.remove("bg-trade-primary")
        step.firstElementChild.classList.add("bg-stroke")
      }
    })
  }

  showTooltip(event) {
    const rect = this.inputTarget.getBoundingClientRect()
    const percent = Math.max(0, Math.min(1, (event.clientX - rect.left) / rect.width))
    const value = Math.round(percent * this.inputTarget.max)

    this.tooltipTarget.style.opacity = "1"
    this.tooltipTarget.style.left = `${percent * 100}%`
    this.tooltipTarget.textContent = `${value}%`
  }

  hideTooltip() {
    this.tooltipTarget.style.opacity = "0"
  }
}
