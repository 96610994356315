import { Controller } from "stimulus"
import gsap from "gsap";

export default class extends Controller {
  static targets = ["background", "item"]

  connect() {
    // Set initial position
    const activeItem = this.itemTargets.find(item => item.dataset.active === "true")
    if (activeItem) {
      // Update active item text color immediately
      activeItem.classList.remove('text-gray-300')
      activeItem.classList.add('text-white')

      // Position the background without animation first
      const rect = activeItem.getBoundingClientRect()
      const navRect = this.element.getBoundingClientRect()
      const leftOffset = rect.left - navRect.left

      // Get initial color from data attribute or fallback to default
      const initialColor = activeItem.dataset.color || '#2D4247' // gray-500 fallback

      // Set initial position with opacity 0 and color
      gsap.set(this.backgroundTarget, {
        x: leftOffset,
        width: rect.width,
        opacity: 0,
        backgroundColor: initialColor
      })

      // Remove hidden class and fade in
      this.backgroundTarget.classList.remove('hidden')
      gsap.to(this.backgroundTarget, {
        opacity: 1,
        duration: 0.2,
        ease: "power1.inOut"
      })
    }

    // Add click handlers
    this.itemTargets.forEach(item => {
      item.addEventListener('click', (e) => {
        this.setActiveItem(item)
      })
    })
  }

  setActiveItem(clickedItem) {
    // Update active states
    this.itemTargets.forEach(item => {
      item.dataset.active = "false"
      item.classList.remove('text-white')
      item.classList.add('text-gray-300')
    })

    clickedItem.dataset.active = "true"
    clickedItem.classList.remove('text-gray-300')
    clickedItem.classList.add('text-white')

    // Animate background with color change
    this.moveBackground(clickedItem)
  }

  moveBackground(targetItem) {
    const rect = targetItem.getBoundingClientRect()
    const navRect = this.element.getBoundingClientRect()
    const leftOffset = rect.left - navRect.left
    const newColor = targetItem.dataset.color || '#2D4247'

    gsap.to(this.backgroundTarget, {
      x: leftOffset,
      width: rect.width,
      backgroundColor: newColor,
      duration: 0.1,
      ease: "power2.out"
    })
  }
}
