// app/javascript/controllers/turbo_form_controller.js
import { Controller } from "@hotwired/stimulus"
import { showElement, hideElement } from '@/helpers/animations_helpers'

export default class extends Controller {
  static targets = ["errorMessage"]

  connect() {
    this.clearErrors()
  }

  submit(event) {
    event.preventDefault()
    const form = event.target
    Turbo.navigator.submitForm(form)
  }

  handleSubmit(event) {
    const { success, fetchResponse } = event.detail

    if (success) {
      this.clearErrors()
    } else {
      event.preventDefault()
      fetchResponse.response.json().then(errors => {
        this.clearErrors()

        // Track the first error field to focus
        let firstErrorField = null

        Object.keys(errors).forEach(field => {
          const errorElement = document.getElementById(`${field}_error`)
          if (errorElement) {
            const messageSpan = errorElement.querySelector('span')
            messageSpan.textContent = errors[field].join(", ")
            showElement(errorElement)

            const inputField = this.element.querySelector(`[name$="[${field}]"]`)
            if (inputField) {
              // Store first error field for focusing
              if (!firstErrorField) firstErrorField = inputField

              inputField.value = ''
              inputField.classList.remove('ring-white/10', 'focus:ring-gray-300')
              inputField.classList.add('ring-error-medium', 'focus:ring-error-medium')

              setTimeout(() => {
                inputField.classList.remove('ring-error-medium', 'focus:ring-error-medium')
                inputField.classList.add('ring-white/10', 'focus:ring-gray-300')
              }, 3000)
            }

            setTimeout(() => {
              hideElement(errorElement)
            }, 3000)
          }
        })

        // Focus the first error field if one exists
        if (firstErrorField) {
          firstErrorField.focus()
        }
      })
    }
  }

  clearErrors() {
    this.errorMessageTargets.forEach(element => {
      hideElement(element)
      element.querySelector('span').textContent = ''

      this.element.querySelectorAll('input[type="text"], input[type="email"]').forEach(input => {
        input.classList.remove('ring-error-medium', 'focus:ring-error-medium')
        input.classList.add('ring-white/10', 'focus:ring-gray-300')
      })
    })
  }
}
