import { Controller } from 'stimulus';
import { hideElement, showElement } from '@/helpers/animations_helpers';

export default class NewTextFieldController extends Controller {
  static targets = [
    'label',
    'inputEl',
    'errorIcon',
    'trailingIcon',
    'leadingIcon',
    'addOnValue',
    'errorText',
  ];

  static values = { addOnValue: String };

  static inputClasses = [
    'bg-white/5',
    'text-white',
    'ring-white/10',
    'placeholder:text-gray-300',
    'focus:ring-sky-medium',
  ];

  static errorClasses = ['text-red-500', 'ring-red-500', 'bg-white/10'];

  handleFocusInput() {
    this.labelTarget.childNodes.forEach((node) => {
      if (node instanceof HTMLInputElement) {
        node.focus();
      }
    });
  }

  addError(text) {
    this.addErrorClasses();
    this.errorTextTarget.textContent = text;
    showElement(this.errorTextTarget);
  }

  removeError() {
    this.removeErrorClasses();
    hideElement(this.errorTextTarget);
  }

  handleValidateInput() {
    const pattern = this.inputElTarget.getAttribute('pattern');
    const value = this.inputElTarget.value;
    const notEmpty = value.length > 0;
    const numericValue = parseFloat(value);

    if (notEmpty) {
      // Check pattern if it exists
      if (pattern && !new RegExp(pattern).test(value)) {
        this.addErrorClasses();
        return;
      }

      // Check numeric constraints
      const min = parseFloat(this.inputElTarget.getAttribute('min'));
      const max = parseFloat(this.inputElTarget.getAttribute('max'));

      if (!isNaN(numericValue)) {
        if ((!isNaN(min) && numericValue < min) || (!isNaN(max) && numericValue > max)) {
          this.addErrorClasses();
          return;
        }
      }

      this.removeErrorClasses();
    } else {
      // Handle empty value
      if (this.inputElTarget.hasAttribute('required')) {
        this.addErrorClasses();
      } else {
        this.removeErrorClasses();
      }
    }
  }

  addErrorClasses() {
    this.inputElTarget.classList.add(...NewTextFieldController.errorClasses);
    this.inputElTarget.classList.remove(...NewTextFieldController.inputClasses);

    // We hide the trailing icon in favour of the error
    // icon when the input does not match the pattern
    if (this.hasTrailingIconTarget) {
      this.trailingIconTarget.classList.add('hidden');
    } else {
      this.inputElTarget.classList.add('pr-10');
    }

    this.errorIconTarget.classList.remove('hidden');
    this.errorIconTarget.classList.add('flex');
  }

  removeErrorClasses() {
    this.inputElTarget.classList.remove(...NewTextFieldController.errorClasses);
    this.inputElTarget.classList.add(...NewTextFieldController.inputClasses);

    // Renables the dynamic icon
    if (this.hasTrailingIconTarget) {
      this.trailingIconTarget.classList.remove('hidden');
    } else {
      this.inputElTarget.classList.remove('pr-10');
    }

    this.errorIconTarget.classList.add('hidden');
  }

  onAddOnClick() {
    this.inputElTarget.value = this.addOnValueValue

    // Dispatch custom event for max value needed for withdraw_controller.js
    this.element.dispatchEvent(new CustomEvent('newTextField:valueChanged', {
      bubbles: true
    }))
  }

  onAddOnPasteClick() {
    navigator.clipboard.readText()
      .then(text => {
        this.inputElTarget.value = text
        // Dispatch custom event for pasted address needed for withdraw_controller.js
        this.element.dispatchEvent(new CustomEvent('newTextField:addressPasted', {
          bubbles: true
        }))
      })
      .catch(err => {
        console.error('Failed to read clipboard:', err)
      })
  }

  setAddOnValue(value) {
    this.addOnValueValue = value;
  }

  showAddOn() {
    this.addOnValueTarget.classList.remove('hidden');
  }

  hideAddOn() {
    this.addOnValueTarget.classList.add('hidden');
  }
}
