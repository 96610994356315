import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLElement> {
  static targets = ['content', 'emptyState'];
  static values = {
    observeSelector: String,
  };

  // Declare types for targets and values
  declare readonly contentTarget: HTMLElement;
  declare readonly emptyStateTarget: HTMLElement;
  declare readonly observeSelectorValue: string;

  // Declare type for MutationObserver
  private observer: MutationObserver | null = null;

  connect(): void {
    // Set up mutation observer to watch for changes
    this.observer = new MutationObserver(this.checkContent.bind(this));
    this.observer.observe(this.contentTarget, {
      childList: true,
      subtree: true,
    });

    // Check initial state
    this.checkContent();
  }

  disconnect(): void {
    this.observer?.disconnect();
  }

  private checkContent(): void {
    const hasContent = this.observeSelectorValue
      ? this.contentTarget.querySelector(this.observeSelectorValue) !== null
      : this.contentTarget.children.length > 0;

    this.contentTarget.classList.toggle('hidden', !hasContent);
    this.emptyStateTarget.classList.toggle('hidden', hasContent);
  }
}
