import { Controller } from 'stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  static values = { rank: Number };

  gradientTimeline = null;

  connect() {
    if (this.rankValue <= 3) {
      this.setupBorderAnimation();
    }
  }

  disconnect() {
    if (this.gradientTimeline) {
      this.gradientTimeline.kill();
    }
  }

  setupBorderAnimation() {
    // More vibrant color combinations for each rank
    const colors = {
      1: [ // Gold variations
        '#FFD700', // Pure gold
        '#FDB931', // Slightly orange gold
        '#FFE55C', // Lighter gold
        '#FFA500'  // Deep gold
      ],
      2: [ // Silver variations
        '#C0C0C0', // Pure silver
        '#E8E8E8', // Light silver
        '#A8A8A8', // Dark silver
        '#D9D9D9'  // Medium silver
      ],
      3: [ // Bronze variations
        '#CD7F32', // Pure bronze
        '#DFA878', // Light bronze
        '#B87333', // Dark bronze
        '#E69A56'  // Warm bronze
      ]
    };

    const rankColors = colors[this.rankValue];

    // Create a seamless color rotation
    this.gradientTimeline = gsap.timeline({ repeat: -1 });

    // Add each color transition to create a circular effect
    rankColors.forEach((color, index) => {
      const nextColor = rankColors[(index + 1) % rankColors.length];

      this.gradientTimeline.to(this.element, {
        borderColor: nextColor,
        duration: 0.75, // Faster transitions
        ease: "power1.inOut" // Smooth transition between colors
      });
    });
  }
}
