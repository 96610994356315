import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"
import {showElement, hideElement} from '~/helpers/animations_helpers';

export default class extends Controller {
  static targets = ["input", "hiddenInput", "spinner", "error"]
  static values = {
    submitOnComplete: Boolean
  }

  connect() {
    // Initially disable all inputs except the first one
    this.inputTargets.forEach((input, index) => {
      input.disabled = index !== 0
    })
    this.handleInput = this.handleInput.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
    this.handlePaste = this.handlePaste.bind(this)

    // Add form submit listener to show spinner
    if (this.submitOnCompleteValue) {
      const form = this.element.closest('form')
      if (form) {
        form.addEventListener('turbo:submit-start', this.showSpinner.bind(this))
        form.addEventListener('turbo:submit-end', this.hideSpinner.bind(this))
      }
    }

    this.inputTargets.forEach(input => {
      input.addEventListener('input', this.handleInput)
      input.addEventListener('keydown', this.handleKeydown)
      input.addEventListener('paste', this.handlePaste)
    })

    this.inputTargets[0].focus()

    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    this.inputTargets.forEach(input => {
      input.removeEventListener('input', this.handleInput)
      input.removeEventListener('keydown', this.handleKeydown)
      input.removeEventListener('paste', this.handlePaste)
    })

    if (this.submitOnCompleteValue) {
      const form = this.element.closest('form')
      if (form) {
        form.removeEventListener('turbo:submit-start', this.showSpinner.bind(this))
        form.removeEventListener('turbo:submit-end', this.hideSpinner.bind(this))
      }
    }

    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const { action, target } = event.detail.newStream;

    if (target === 'two_factor_auth' && action === 'replace') {
      event.preventDefault();
      this.handleFrameReplace(event.detail.newStream);
    }
  }

  handleFrameReplace(newStream) {
    const templateContent = newStream.querySelector('template').content;
    const newFrame = templateContent.querySelector('turbo-frame');

    // Replace the current frame with the new one
    this.element.replaceWith(newFrame);

    // Wait for next tick to ensure Stimulus has initialized the new controller
    setTimeout(() => {
      const newController = this.application.getControllerForElementAndIdentifier(
        newFrame,
        'two-factor-auth'
      );

      console.log(newController.hasErrorTarget)

      if (newController?.hasErrorTarget) {
        showElement(newController.errorTarget);

        setTimeout(() => {
          hideElement(newController.errorTarget);
        }, 1500);
      }
    }, 0);
  }

  showSpinner() {
    gsap.to(this.spinnerTarget, {
      opacity: 1,
      scale: 1,
      duration: 0.2,
      ease: "power2.out"
    })
  }

  hideSpinner() {
    gsap.to(this.spinnerTarget, {
      opacity: 0,
      scale: 0.75,
      duration: 0.2,
      ease: "power2.in"
    })
  }

  handlePaste(event) {
    event.preventDefault()

    // Get pasted content from clipboard
    const pastedText = (event.clipboardData || window.clipboardData).getData('text')

    // Validate pasted content
    if (!/^\d{6}$/.test(pastedText)) {
      return
    }

    // Distribute digits across inputs
    const digits = pastedText.split('')
    this.inputTargets.forEach((input, index) => {
      input.value = digits[index]
      input.disabled = true
    })

    // Update hidden input and submit if needed
    this.updateHiddenInputAndSubmit()
  }

  handleInput(event) {
    const input = event.target
    const value = input.value
    const position = parseInt(input.dataset.position)

    // Only allow numbers
    if (!/^\d*$/.test(value)) {
      input.value = ''
      return
    }

    // When a value is entered
    if (value !== '') {
      // Disable the current input
      input.disabled = true
      input.blur()

      const nextInput = this.inputTargets[position + 1]
      if (nextInput) {
        // Enable and focus the next input
        nextInput.disabled = false
        nextInput.focus()
      } else {
        // If it's the last input, update hidden input and submit if needed
        this.updateHiddenInputAndSubmit()
      }
    }
  }

  handleKeydown(event) {
    if (event.key === 'Backspace') {
      const currentInput = event.target
      const position = parseInt(currentInput.dataset.position)

      if (!currentInput.value && position > 0) {
        // If current input is empty and we're not at the first position
        const prevInput = this.inputTargets[position - 1]

        // Disable current input
        currentInput.disabled = true
        currentInput.blur()

        // Enable and focus previous input
        prevInput.disabled = false
        prevInput.focus()
        prevInput.value = ''

        event.preventDefault()
      }
    }
  }

  updateHiddenInputAndSubmit() {
    const enteredCode = this.inputTargets.map(input => input.value).join('')
    this.hiddenInputTarget.value = enteredCode

    // If all inputs are filled and submitOnComplete is true, submit the form
    if (this.submitOnCompleteValue && enteredCode.length === 6) {
      const form = this.element.closest('form')
      if (form) {
        form.requestSubmit()
      }
    }
  }
}
