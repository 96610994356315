import { Controller } from 'stimulus'
import gsap from 'gsap'

export default class extends Controller {
  static targets = ['muted', 'image', 'button', 'logo', 'description']

  connect() {
    // Force GPU acceleration on elements that will animate
    gsap.set([this.imageTarget, this.logoTarget, this.descriptionTarget], {
      willChange: 'transform',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)'
    })

    // Set initial states once
    gsap.set(this.descriptionTarget, {
      y: 10,
      opacity: 0
    })

    this.boundAnimationPlay = this.animationPlay.bind(this)
    this.boundAnimationReverse = this.animationReverse.bind(this)
    this.element.addEventListener('mouseenter', this.boundAnimationPlay)
    this.element.addEventListener('mouseleave', this.boundAnimationReverse)

    // Create a more efficient timeline
    this.timeline = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.2,
        ease: 'power2.inOut',
        force3D: true // Forces 3D transforms
      }
    })

    // Group transforms together, opacity changes separate
    this.timeline
      .to(this.imageTarget, {
        scale: 1.1,
      }, 0)
      .to([this.mutedTarget, this.buttonTarget, this.descriptionTarget], {
        opacity: 1,
      }, 0)
      .to(this.logoTarget, {
        y: -40,
      }, 0)
      .to(this.descriptionTarget, {
        y: 0,
      }, 0)
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.boundAnimationPlay)
    this.element.removeEventListener('mouseleave', this.boundAnimationReverse)
    if (this.timeline) {
      this.timeline.kill()
    }
  }

  animationPlay() {
    if (this.timeline) {
      this.timeline.play()
    }
  }

  animationReverse() {
    if (this.timeline) {
      this.timeline.reverse()
    }
  }
}
