import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initial validation on connect
    this.validateForm()

    // Add form submit handler
    this.element.addEventListener('turbo:submit-end', this.handleSubmit.bind(this))

    // Listen for custom events from the text field component
    this.element.addEventListener('newTextField:valueChanged', this.validateForm.bind(this))
    this.element.addEventListener('newTextField:addressPasted', this.validateForm.bind(this))
  }

  disconnect() {
    // Clean up event listeners
    this.element.removeEventListener('turbo:submit-end', this.handleSubmit.bind(this))
    this.element.removeEventListener('newTextField:valueChanged', this.validateForm.bind(this))
    this.element.removeEventListener('newTextField:addressPasted', this.validateForm.bind(this))
  }

  // Get the address input element
  getAddressInput() {
    return this.element.querySelector('input[name="withdrawal_address"]')
  }

  // Validates the Ethereum address format
  validateAddress() {
    const addressInput = this.getAddressInput()
    if (!addressInput) return false

    const ethereumAddressRegex = /^0x[A-Fa-f0-9]{40}$/
    return ethereumAddressRegex.test(addressInput.value)
  }

  // Validates the withdrawal amount against min/max constraints
  validateAmount() {
    const amountInput = this.element.querySelector('input[name="withdrawal_amount"]')
    if (!amountInput) return false

    const amount = parseFloat(amountInput.value)
    const min = parseFloat(amountInput.min)
    const max = parseFloat(amountInput.max)

    return !isNaN(amount) && amount >= min && amount <= max
  }

  // Validates the entire form
  validateForm() {
    const addressValid = this.validateAddress()
    const amountValid = this.validateAmount()
    const confirmCheckbox = this.element.querySelector('input[name="confirm_checkbox"]')
    const checkboxValid = confirmCheckbox ? confirmCheckbox.checked : false

    // Only enable submit if all validations pass
    const submitButton = this.element.querySelector('input[type="submit"]')
    if (submitButton) {
      submitButton.disabled = !(addressValid && amountValid && checkboxValid)
    }
  }

  // Handle form submission
  handleSubmit(event) {
    // Only proceed if the submission was successful
    if (event.detail.success) {
      // Reset form inputs
      const addressInput = this.getAddressInput()
      const amountInput = this.element.querySelector('input[name="withdrawal_amount"]')
      const confirmCheckbox = this.element.querySelector('input[name="confirm_checkbox"]')

      if (addressInput) addressInput.value = ''
      if (amountInput) amountInput.value = ''
      if (confirmCheckbox) confirmCheckbox.checked = false

      // Re-disable submit button
      const submitButton = this.element.querySelector('input[type="submit"]')
      if (submitButton) submitButton.disabled = true
    }
  }
}
