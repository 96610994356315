// app/javascript/controllers/button_loader_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "text", "spinner"]

  click(event) {
    // Get computed styles to capture the actual rendered dimensions
    const computedStyle = window.getComputedStyle(this.buttonTarget)
    const width = computedStyle.width
    const height = computedStyle.height

    // Lock in both dimensions
    this.buttonTarget.style.width = width
    this.buttonTarget.style.height = height

    this.buttonTarget.disabled = true

    this.textTarget.classList.add("hidden")

    this.spinnerTarget.classList.remove("hidden")
  }
}
