import { Controller } from 'stimulus';
import { gsap } from 'gsap';

export default class extends Controller {
  static targets = ['noDeposits', 'deposits'];

  connect() {
    this.boundTurboStreamHandler = this.handleTurboStream.bind(this);
    document.addEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundTurboStreamHandler
    );
  }

  handleTurboStream(event) {
    const uuidPattern = /deposit_\d+/;
    const { action, target } = event.detail.newStream;
    console.log(action, target);

    if (target === 'recent_deposits') {
      event.preventDefault();
      if (action === 'append' || action === 'prepend') {
        this.handleCardPrepend(event.detail.newStream);
      } else if (action === 'replace') {
        this.handleFrameReplace(event.detail.newStream);
      }
    } else if (action === 'replace' && uuidPattern.test(target)) {
      event.preventDefault();
      this.handleStatusUpdate(target, event.detail.newStream);
    }
  }

  handleFrameReplace(newStream) {
    const templateContent = newStream.querySelector('template').content;
    const newFrame = templateContent.querySelector('turbo-frame');

    if (this.hasNoDepositsTarget && newFrame.children.length > 1) {
      // Transitioning from no deposits to having deposits
      this.fadeOutNoDeposits(newFrame);
    } else if (!this.hasNoDepositsTarget && newFrame.children.length === 1) {
      // Transitioning to no deposits state
      this.fadeOutDeposits(newFrame);
    } else {
      // Direct replacement
      this.depositsTarget.innerHTML = newFrame.innerHTML;
    }
  }

  handleCardPrepend(newStream) {
    const templateContent = newStream.querySelector('template').content;
    const newCard = templateContent.querySelector('turbo-frame').cloneNode(true);

    // If we have the no deposits message, remove it first
    if (this.hasNoDepositsTarget) {
      this.noDepositsTarget.remove();
    }

    // Set initial styles
    newCard.style.overflow = 'hidden';
    newCard.style.height = '0px';
    newCard.style.opacity = '0';
    newCard.style.marginBottom = '0px'; // To handle the gap animation smoothly

    this.depositsTarget.insertAdjacentElement('afterbegin', newCard);

    // Create animation timeline
    gsap.timeline()
      .to(newCard, {
        height: '112px',
        duration: 0.3,
        ease: 'power2.out'
      })
      .to(newCard, {
        opacity: 1,
        y: 0,
        duration: 0.2,
        onComplete: () => {
          // Clean up inline styles after animation
          newCard.style.height = '';
          newCard.style.overflow = '';
          newCard.style.opacity = '';
          newCard.style.marginBottom = '';
        }
      }, '-=0.1'); // Slightly overlap with height animation
  }

  handleStatusUpdate(cardId, newStream) {
    const templateContent = newStream.querySelector('template').content;
    const newCard = templateContent.querySelector('turbo-frame').cloneNode(true);
    const oldCard = document.getElementById(cardId);

    if (oldCard) {
      oldCard.replaceWith(newCard);

      gsap.fromTo(
        newCard,
        { backgroundColor: 'rgba(23, 169, 255, 0.2)' },
        {
          backgroundColor: 'rgba(0, 128, 0, 0.2)',
          duration: 0.5,
          onComplete: () => {
            gsap.to(newCard, {
              backgroundColor: 'var(--gray-600-hex)',
              duration: 0.5,
            });
          },
        }
      );

      const statusElement = newCard.querySelector('.flex.items-center.gap-2');
      if (statusElement) {
        gsap.from(statusElement, {
          scale: 1.2,
          duration: 0.3,
        });
      }
    }
  }

  fadeOutNoDeposits(newContent) {
    gsap.to(this.noDepositsTarget, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        this.depositsTarget.innerHTML = newContent.innerHTML;
        this.fadeInDeposits();
      },
    });
  }

  fadeOutDeposits(newContent) {
    gsap.to(this.depositsTarget, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        this.depositsTarget.innerHTML = newContent.innerHTML;
        gsap.to(this.depositsTarget, {
          opacity: 1,
          duration: 0.3,
        });
      },
    });
  }

  fadeInDeposits() {
    gsap.from(this.depositsTarget.children, {
      opacity: 0,
      y: -20,
      duration: 0.3,
      stagger: 0.1,
    });
  }
}
