import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['price', 'quantity', 'total', 'submit'];

  connect() {
    this.calculateTotal();
  }

  calculateTotal() {
    const price = parseFloat(this.priceTarget?.value || 0);
    const quantity = parseFloat(this.quantityTarget?.value || 0);
    const total = price * quantity;

    if (this.hasTargets) {
      this.totalTarget.textContent = `${total.toFixed(8)}`;
    }
  }

  submitOrder(event) {
    event.preventDefault();
    this.submitTarget.disabled = true;

    // For market orders, we set the price as the quantity * current market price
    if (
      this.element.querySelector('[name*="[order_type]"]').value ===
        'market_order' &&
      this.element.querySelector('[name*="[category]"]').value === 'bid'
    ) {
      const quantity = parseFloat(this.quantityTarget.value);
      this.element.querySelector('[name*="[price]"]').value = quantity;
    }

    this.element.requestSubmit();
  }
}
