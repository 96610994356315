// search_controller.js
import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static targets = ["container", "input", "closeButton", "searchFrame", "panel"]

  // Debounce timeout handler
  searchTimeout = null

  connect() {
    // Initialize GSAP timeline for animations
    this.timeline = gsap.timeline({
      defaults: { duration: 0.3, ease: "power2.out" }
    })

    // Initialize panel state
    this.panelTarget.style.opacity = 0
    this.panelTarget.style.transformOrigin = "top"
    this.panelTarget.style.transform = "scale(0.95)"

    // Add click event listener to document to handle clicks outside
    document.addEventListener('click', this.handleDocumentClick.bind(this))
  }

  disconnect() {
    // Clean up event listener
    document.removeEventListener('click', this.handleDocumentClick.bind(this))
  }

  handleDocumentClick(event) {
    // If panel is hidden, do nothing
    if (this.panelTarget.classList.contains('hidden')) return

    // Check if click is outside both the panel and the input
    const isClickInside = this.panelTarget.contains(event.target) ||
      this.containerTarget.contains(event.target)

    if (!isClickInside) {
      this.hidePanel()
    }
  }

  // Focus handlers
  inputFocus() {
    this.showPanel()
  }

  // Show panel with animation
  showPanel() {
    const panel = this.panelTarget
    panel.classList.remove("hidden")

    // Create a smooth animation sequence
    gsap.timeline()
      .to(panel, {
        opacity: 1,
        scale: 1,
        duration: 0.3,
        ease: "power2.out"
      })
  }

  // Hide panel with animation
  hidePanel() {
    const panel = this.panelTarget

    gsap.to(panel, {
      opacity: 0,
      scale: 0.95,
      duration: 0.3,
      ease: "power2.in",
      onComplete: () => {
        panel.classList.add("hidden")
      }
    })
  }

  // Handle container click to focus input
  containerClick() {
    this.inputTarget.focus()
  }

  // Handle input changes
  inputChange() {
    const value = this.inputTarget.value

    // Handle close button visibility
    if (value.length > 0) {
      this.showCloseButton()
    } else {
      this.hideCloseButton()
      this.showNoResults()
    }

    // Clear existing timeout if user is still typing
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
    }

    // Only trigger search if 3 or more characters
    if (value.length >= 3) {
      // Wait for 300ms of no typing before searching
      this.searchTimeout = setTimeout(() => {
        this.performSearch(value)
      }, 300)
    }
  }

  // Show close button with fade animation
  showCloseButton() {
    const button = this.closeButtonTarget
    if (button.classList.contains("hidden")) {
      button.classList.remove("hidden")
      gsap.fromTo(button,
        { opacity: 0 },
        { opacity: 1, duration: 0.3 }
      )
    }
  }

  // Hide close button with fade animation
  hideCloseButton() {
    const button = this.closeButtonTarget
    gsap.to(button, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        button.classList.add("hidden")
      }
    })
  }

  // Clear input field
  clearInput() {
    this.inputTarget.value = ""
    this.hideCloseButton()
    this.showNoResults()
  }

  // Perform search with animations
  async performSearch(query) {
    // Show loading spinner
    await this.transitionContent(this.getSpinnerTemplate())

    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 300))

    // Check if query exactly matches the start of "gunzilla" (case insensitive)
    const matches = /^gun/i.test(query.trim())

    if (matches) {
      await this.transitionContent(this.getGunzillaTemplate())
    } else {
      await this.transitionContent(this.getNoResultsTemplate())
    }
  }

  // Show no results template
  async showNoResults() {
    await this.transitionContent(this.getNoResultsTemplate())
  }

  // Transition content with fade animation
  async transitionContent(newContent) {
    const frame = this.searchFrameTarget

    // Fade out current content
    await gsap.to(frame, {
      opacity: 0,
      duration: 0.3
    })

    // Update content
    frame.innerHTML = newContent.innerHTML

    // Fade in new content
    await gsap.to(frame, {
      opacity: 1,
      duration: 0.3
    })
  }

  // Get template content by selecting the appropriate template element
  getTemplateContent(index) {
    const template = this.element.querySelectorAll("template")[index]
    const tempDiv = document.createElement('div')
    tempDiv.appendChild(template.content.cloneNode(true))
    return tempDiv
  }

  getNoResultsTemplate() {
    return this.getTemplateContent(0)
  }

  getSpinnerTemplate() {
    return this.getTemplateContent(1)
  }

  getGunzillaTemplate() {
    return this.getTemplateContent(2)
  }
}
