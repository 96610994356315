import { Controller } from 'stimulus';
import { createChart } from 'lightweight-charts';
import { gsap } from 'gsap';

export default class extends Controller {
  static targets = ['spinner', 'chart'];
  static values = { data: Array };

  connect() {
    this.initializeChart();
    this.setupChartUpdates();
  }

  disconnect() {
    if (this.chart) {
      this.chart.remove();
    }
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
    }
    window.removeEventListener('chartUpdate', this.handleChartUpdate);
  }

  initializeChart() {
    gsap.set(this.chartTarget, { display: 'none', autoAlpha: 0 });

    const tl = gsap.timeline();

    tl.to(this.spinnerTarget, {
      autoAlpha: 0,
      duration: 0.2,
    }).set(this.spinnerTarget, { display: 'none' });

    tl.set(this.chartTarget, { display: 'block', autoAlpha: 0 });

    tl.call(() => {
      const chartOptions = {
        layout: {
          textColor: 'white',
          background: { type: 'solid', color: '#141E20' },
        },
        autoSize: true,
        grid: {
          vertLines: { color: '#314146' },
          horzLines: { color: '#314146' },
        },
      };

      this.chart = createChart(this.chartTarget, chartOptions);
      this.candlestickSeries = this.chart.addCandlestickSeries({
        upColor: '#03815B',
        downColor: '#752247',
        borderVisible: false,
        wickUpColor: '#03815B',
        wickDownColor: '#752247',
      });

      this.candlestickSeries.setData(this.dataValue);
      this.candlestickSeries.priceScale().applyOptions({
        autoScale: false,
      });
      this.chart.timeScale().fitContent();

      this.resizeHandler = () => {
        this.chart.timeScale().fitContent();
      };

      window.addEventListener('resize', this.resizeHandler);
    });

    tl.to(this.chartTarget, {
      autoAlpha: 1,
      duration: 0.3,
    });
  }

  setupChartUpdates() {
    this.handleChartUpdate = this.handleChartUpdate.bind(this);
    window.addEventListener('chartUpdate', this.handleChartUpdate);
  }

  handleChartUpdate(event) {
    const candle = event.detail;
    const lastData = this.dataValue[this.dataValue.length - 1];

    if (lastData && lastData.time === candle.time) {
      // Update existing candle
      this.candlestickSeries.update(candle);

      // Update our data array
      this.dataValue[this.dataValue.length - 1] = candle;
    } else {
      // Add new candle
      this.candlestickSeries.update(candle);
      this.dataValue.push(candle);

      // Optionally remove old candles to maintain a fixed window
      if (this.dataValue.length > 24) {
        // Keep 24 hours of data
        this.dataValue.shift();
        // Note: lightweight-charts handles removal of old data automatically
      }
    }
  }
}
