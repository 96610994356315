import { Controller } from "stimulus";
import gsap from "gsap";

export default class extends Controller {
  static targets = ['template', 'content']

  static values = {
    input: String
  }

  connect() {
    if(!this.hasInputValue) {
      return console.error('Copy value is required');
    }
    console.log(this.inputValue)
    console.log('Connected with copy value:', this.inputValue);
  }

  async copyToClipboard(event) {
    try {
      // Try modern clipboard API first
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(this.inputValue);
        this.showCopiedAnimation();
        return;
      }

      // Create and configure textarea
      const textarea = document.createElement('textarea');
      textarea.value = this.inputValue;
      textarea.style.cssText = 'position: fixed; left: 0; top: 0; width: 2em; height: 2em; padding: 0; opacity: 0; border: none;';

      // Add to document
      document.body.appendChild(textarea);

      const isIOS = navigator.userAgent.match(/ipad|iphone/i);

      if (isIOS) {
        try {
          const range = document.createRange();
          range.selectNodeContents(textarea);

          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          textarea.setSelectionRange(0, textarea.value.length);
        } catch (iosErr) {
          console.error('iOS selection error:', iosErr);
        }
      } else {
        textarea.focus();
        textarea.select();
      }

      // Try to copy
      try {
        const successful = document.execCommand('copy');

        if (!successful) {
          throw new Error('Copy command returned false');
        }
      } catch (copyErr) {
        throw copyErr;
      }

      // Cleanup
      document.body.removeChild(textarea);

      this.showCopiedAnimation();

    } catch (err) {
      console.error('Copy failed:', err);
      // Maybe show error to user
      this.contentTarget.innerHTML = "Copy failed!";
      setTimeout(() => {
        this.contentTarget.innerHTML = "Copy address";
      }, 2000);
    }
  }

  showCopiedAnimation() {
    const originalContent = this.contentTarget.innerHTML;
    const newContent = this.hasTemplateTarget
      ? this.templateTarget.innerHTML
      : "Copied";

    const originalWidth = this.contentTarget.offsetWidth;
    this.contentTarget.style.width = `${originalWidth}px`;

    const tl = gsap.timeline({
      onComplete: () => {
        this.contentTarget.style.width = '';
      }
    });

    tl.to(this.contentTarget, {
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        this.contentTarget.innerHTML = newContent;
      }
    })
      .to(this.contentTarget, {
        opacity: 1,
        duration: 0.3
      })
      .to(this.contentTarget, {
        opacity: 0,
        duration: 0.3,
        delay: 1,
        onComplete: () => {
          this.contentTarget.innerHTML = originalContent;
        }
      })
      .to(this.contentTarget, {
        opacity: 1,
        duration: 0.3
      });
  }
}
