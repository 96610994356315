import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "balance", "slider", "receive"]

  connect() {
    const fullText = this.receiveTarget.textContent.trim()
    this.tokenSymbol = fullText.split(' ').slice(-1)[0]
    this.isSelling = this.tokenSymbol === '$'

    this.updateInputFromSlider()
    this.updateSliderBackground()
  }

  updateSliderBackground() {
    const percent = this.sliderTarget.value
    this.sliderTarget.style.setProperty('--value-percent', `${percent}%`)
  }

  updateTokenAmount(inputValue) {
    const tokenPrice = parseFloat(this.receiveTarget.dataset.price)
    let amount

    if (inputValue) {
      // If selling (receiving $), multiply by price
      // If buying (receiving tokens), divide by price
      amount = this.isSelling
        ? (inputValue * tokenPrice).toFixed(2)
        : (inputValue / tokenPrice).toFixed(2)
    } else {
      amount = "0"
    }

    this.receiveTarget.textContent = `${amount} ${this.tokenSymbol}`

    if (inputValue) {
      this.receiveTarget.classList.remove('text-gray-400')
      this.receiveTarget.classList.add('text-white')
    } else {
      this.receiveTarget.classList.add('text-gray-400')
      this.receiveTarget.classList.remove('text-white')
    }
  }

  updateInputFromSlider() {
    const sliderValue = this.sliderTarget.value
    const balance = parseFloat(this.balanceTarget.dataset.value)

    if (sliderValue == 0) {
      this.inputTarget.value = ""
      this.updateTokenAmount("")
    } else {
      const amount = (balance * (sliderValue / 100)).toFixed(2)
      this.inputTarget.value = amount
      this.updateTokenAmount(amount)
    }
    this.updateSliderBackground()
  }

  sliderTargetConnected() {
    this.sliderTarget.addEventListener("input", () => {
      this.updateInputFromSlider()
    })
  }

  inputTargetConnected() {
    this.inputTarget.addEventListener("input", (e) => {
      let inputValue = e.target.value
      const balance = parseFloat(this.balanceTarget.dataset.value)

      // Remove any negative signs
      inputValue = inputValue.replace(/-/g, '')

      // Ensure only one decimal point
      if ((inputValue.match(/\./g) || []).length > 1) {
        inputValue = inputValue.replace(/\.(?=.*\.)/g, '')
      }

      // Convert to number and constrain to balance
      let value = parseFloat(inputValue) || 0
      value = Math.min(value, balance)

      // Update input value if it was changed
      if (value !== parseFloat(inputValue)) {
        e.target.value = value === 0 ? "" : value.toFixed(2)
      }

      // Update token amount
      this.updateTokenAmount(value)

      // Update slider position
      const percentage = Math.min((value / balance) * 100, 100)
      this.sliderTarget.value = percentage
      this.updateSliderBackground()
    })
  }
}
