import { Controller } from "stimulus"
import QRCode from "qrcode"

export default class extends Controller {
  static values = { address: String, size: Number }
  static targets = ["qrContainer", "logo"]

  connect() {
    if (this.addressValue) {
      this.createQRCodeWithLogo()
    }
  }

  async createQRCodeWithLogo() {
    try {
      const scale = 2
      const qrSize = this.sizeValue * scale
      const logoSize = 18 * scale

      // Get the CSS variable value
      const skyMediumColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--rich-black-hex')
        .trim() // trim() removes any whitespace

      const qrCanvas = document.createElement('canvas')
      qrCanvas.width = qrSize
      qrCanvas.height = qrSize

      await QRCode.toCanvas(qrCanvas, this.addressValue, {
        width: qrSize,
        height: qrSize,
        margin: 1,
        errorCorrectionLevel: 'H'
      })

      const finalCanvas = document.createElement('canvas')
      finalCanvas.width = qrSize
      finalCanvas.height = qrSize
      finalCanvas.style.width = `${this.sizeValue - 20}px`
      finalCanvas.style.height = `${this.sizeValue - 20}px`

      const ctx = finalCanvas.getContext('2d', { alpha: false })
      ctx.imageSmoothingEnabled = false
      ctx.drawImage(qrCanvas, 0, 0)

      const originalSvg = this.logoTarget.querySelector('svg')
      const logoSvg = originalSvg.cloneNode(true)
      logoSvg.removeAttribute('class')
      logoSvg.setAttribute('width', logoSize.toString())
      logoSvg.setAttribute('height', logoSize.toString())
      logoSvg.setAttribute('fill', skyMediumColor)

      const logoX = (qrSize - logoSize) / 2
      const logoY = (qrSize - logoSize) / 2

      const padding = 4 * scale
      ctx.fillStyle = 'white'
      ctx.fillRect(logoX - padding, logoY - padding, logoSize + (padding * 2), logoSize + (padding * 2))

      const svgData = new XMLSerializer().serializeToString(logoSvg)
      const img = new Image()

      await new Promise((resolve, reject) => {
        img.onload = resolve
        img.onerror = reject

        const cleanSvgData = svgData
          .replace(/fill="currentColor"/g, `fill="${skyMediumColor}"`)
          .replace(/fill="none"/g, `fill="${skyMediumColor}"`)

        img.src = this.svgToDataUrl(cleanSvgData)
      })

      ctx.drawImage(img, logoX, logoY, logoSize, logoSize)

      this.qrContainerTarget.innerHTML = ''
      this.qrContainerTarget.appendChild(finalCanvas)

    } catch (error) {
      console.error("Error in createQRCodeWithLogo:", error)
    }
  }

  svgToDataUrl(cleanSvgData) {
    // Convert string to Uint8Array using TextEncoder
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(cleanSvgData);

    // Convert Uint8Array to base64 string
    // Use Buffer in Node.js environment
    if (typeof Buffer !== 'undefined') {
      const base64String = Buffer.from(uint8Array).toString('base64');
      return `data:image/svg+xml;base64,${base64String}`;
    }

    // Fallback for browser environment
    const base64String = window.btoa(
      Array.from(uint8Array)
        .map(byte => String.fromCharCode(byte))
        .join('')
    );

    return `data:image/svg+xml;base64,${base64String}`;
  }
}
