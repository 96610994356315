import { Controller } from "stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  static targets = ["tooltip"]
  static values = {
    position: String,
    delay: { type: Number, default: 300 }
  }

  connect() {
    this.tooltipTarget.style.position = "fixed"
    this.tooltipTarget.style.display = "none"
    this.tooltipTarget.style.opacity = "0"
    this.tooltipTarget.style.zIndex = "1000"
    this.isOpen = false
    this.isMobile = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    // Add base classes for the tooltip
    this.tooltipTarget.classList.add('tooltip')
    this.tooltipTarget.classList.add(`tooltip-${this.positionValue || 'bottom'}`)

    if (this.isMobile) {
      this.element.addEventListener("click", this.toggleTooltip)
      document.addEventListener("click", this.handleOutsideClick)
    } else {
      this.element.addEventListener("mouseenter", this.handleMouseEnter)
      this.element.addEventListener("mouseleave", this.handleMouseLeave)
      this.tooltipTarget.addEventListener("mouseenter", this.handleTooltipMouseEnter)
      this.tooltipTarget.addEventListener("mouseleave", this.handleTooltipMouseLeave)
    }
  }

  disconnect() {
    if (this.isMobile) {
      this.element.removeEventListener("click", this.toggleTooltip)
      document.removeEventListener("click", this.handleOutsideClick)
    } else {
      this.element.removeEventListener("mouseenter", this.handleMouseEnter)
      this.element.removeEventListener("mouseleave", this.handleMouseLeave)
      this.tooltipTarget.removeEventListener("mouseenter", this.handleTooltipMouseEnter)
      this.tooltipTarget.removeEventListener("mouseleave", this.handleTooltipMouseLeave)
    }
  }

  handleMouseEnter = () => {
    this.cancelHideTimer()
    this.showTimer = setTimeout(this.showTooltip, 100)
  }

  handleMouseLeave = () => {
    this.cancelShowTimer()
    this.hideTimer = setTimeout(this.hideTooltip, this.delayValue)
  }

  handleTooltipMouseEnter = () => {
    this.cancelHideTimer()
  }

  handleTooltipMouseLeave = (event) => {
    if (!this.tooltipTarget.contains(event.relatedTarget)) {
      this.hideTimer = setTimeout(this.hideTooltip, this.delayValue)
    }
  }

  cancelShowTimer() {
    if (this.showTimer) {
      clearTimeout(this.showTimer)
      this.showTimer = null
    }
  }

  cancelHideTimer() {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer)
      this.hideTimer = null
    }
  }

  toggleTooltip = (event) => {
    event.stopPropagation()
    this.isOpen ? this.hideTooltip() : this.showTooltip()
  }

  handleOutsideClick = (event) => {
    if (this.isOpen && !this.element.contains(event.target) && !this.tooltipTarget.contains(event.target)) {
      this.hideTooltip()
    }
  }

  showTooltip = () => {
    if (this.isOpen) return
    this.isOpen = true
    this.positionTooltip()
    this.tooltipTarget.style.display = "block"
    gsap.to(this.tooltipTarget, {
      opacity: 1,
      duration: 0.3,
      ease: "power2.out"
    })
  }

  hideTooltip = () => {
    if (!this.isOpen) return
    this.isOpen = false
    this.tooltipTarget.style.display = "none"
  }

  positionTooltip() {
    const triggerRect = this.element.getBoundingClientRect()
    const tooltipRect = this.tooltipTarget.getBoundingClientRect()
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    let left, top

    // Get the first text element inside the tooltip
    const tooltipContent = this.tooltipTarget.querySelector('p, span, div')
    const contentRect = tooltipContent ? tooltipContent.getBoundingClientRect() : tooltipRect

    // Remove existing position classes
    this.tooltipTarget.classList.remove('tooltip-top', 'tooltip-right', 'tooltip-bottom', 'tooltip-left')
    // Add the current position class
    this.tooltipTarget.classList.add(`tooltip-${this.positionValue}`)

    switch (this.positionValue) {
      case "top":
        left = triggerRect.left + (triggerRect.width / 2)
        top = triggerRect.top - tooltipRect.height - 10
        break
      case "right":
        left = triggerRect.right + 10
        top = triggerRect.top + (triggerRect.height / 2)
        break
      case "bottom":
        left = triggerRect.left + (triggerRect.width / 2)
        top = triggerRect.bottom + 10
        break
      case "left":
        left = triggerRect.left - tooltipRect.width - 10
        top = triggerRect.top + (triggerRect.height / 2)
        break
      default:
        left = triggerRect.left + (triggerRect.width / 2)
        top = triggerRect.bottom + 10
        this.tooltipTarget.classList.add('tooltip-bottom')
    }

    // Adjust if tooltip is outside viewport
    if (left < 0) left = 0
    if (left + tooltipRect.width > viewportWidth) left = viewportWidth - tooltipRect.width
    if (top < 0) top = 0
    if (top + tooltipRect.height > viewportHeight) top = viewportHeight - tooltipRect.height

    // Set position without animation
    this.tooltipTarget.style.left = `${left}px`
    this.tooltipTarget.style.top = `${top}px`
    if(this.positionValue === "top" || this.positionValue === "bottom") {
      this.tooltipTarget.style.transform = "translateX(-50%)"
    }
    if(this.positionValue === "left" || this.positionValue === "right") {
      this.tooltipTarget.style.transform = "translateY(-50%)"
    }
  }
}
