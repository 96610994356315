import { Controller } from "@hotwired/stimulus"
import gsap from "gsap"

export default class extends Controller {
  static targets = ["label", "input", "text"]

  connect() {
    // Set initial states
    this.updateStyles()

    // Focus first input if exists, but don't interfere with natural tab order
    if (!document.activeElement || document.activeElement === document.body) {
      const firstInput = this.inputTargets.find(input =>
        input.type === 'radio' || input.type === 'checkbox'
      )
      if (firstInput) {
        firstInput.focus()
      } else if (this.hasTextTarget) {
        this.textTarget.focus()
      }
    }

    // Bind the event handler to the instance
    this.boundHandleKeydown = this.handleKeydown.bind(this)

    // Add keyboard event listeners for navigation
    this.element.addEventListener('keydown', this.boundHandleKeydown)
  }

  disconnect() {
    // Clean up event listener
    if (this.boundHandleKeydown) {
      this.element.removeEventListener('keydown', this.boundHandleKeydown)
    }
  }

  handleKeydown(event) {
    // Only handle navigation for radio buttons and checkboxes
    if (!['radio', 'checkbox'].includes(event.target.type)) return

    const currentInput = event.target

    // Handle space key for already checked radio buttons
    if (event.code === 'Space' && currentInput.type === 'radio' && currentInput.checked) {
      event.preventDefault()
      this.handleRadioChange(currentInput)
      return
    }

    // Get all radio/checkbox inputs
    const inputs = this.inputTargets.filter(input =>
      ['radio', 'checkbox'].includes(input.type)
    )
    const currentIndex = inputs.indexOf(currentInput)

    switch (event.key) {
      case 'Tab':
        event.preventDefault()
        const nextIndex = event.shiftKey ?
          (currentIndex - 1 + inputs.length) % inputs.length :
          (currentIndex + 1) % inputs.length
        inputs[nextIndex].focus()
        break
      case 'ArrowDown':
      case 'ArrowRight':
        event.preventDefault()
        const nextInput = inputs[(currentIndex + 1) % inputs.length]
        nextInput.focus()
        break
      case 'ArrowUp':
      case 'ArrowLeft':
        event.preventDefault()
        const prevInput = inputs[(currentIndex - 1 + inputs.length) % inputs.length]
        prevInput.focus()
        break
    }
  }

  inputTargetConnected(input) {
    this.updateLabelStyle(input)
  }

  async change(event) {
    if (event.target.type === 'radio') {
      await this.handleRadioChange(event.target)
    } else if (event.target.type === 'checkbox') {
      this.updateLabelStyle(event.target)
    }
  }

  async handleRadioChange(input) {
    // Reset all labels first for radio buttons
    this.labelTargets.forEach(label => {
      this.resetLabelStyle(label)
    })

    const label = input.closest('[data-due-diligence-input-target="label"]')
    if (label) {
      // Update styles immediately
      this.updateLabelStyle(input)

      // Create flash animation
      await gsap.to(label, {
        backgroundColor: "#ffffff",
        duration: 0.15,
        yoyo: true,
        repeat: 1
      })

      // Submit the form
      input.closest('form').requestSubmit()
    }
  }

  async click(event) {
    if (event.target.type === 'radio' && event.target.checked) {
      await this.handleRadioChange(event.target)
    }
  }

  updateLabelStyle(input) {
    const label = input.closest('[data-due-diligence-input-target="label"]')
    if (!label) return

    if (input.checked) {
      label.classList.remove('bg-card-background-alternative', 'border-gray-600')
      label.classList.add('bg-gray-600', 'border-gray-500')
    } else {
      this.resetLabelStyle(label)
    }
  }

  resetLabelStyle(label) {
    label.classList.remove('bg-gray-600', 'border-gray-500')
    label.classList.add('bg-card-background-alternative', 'border-gray-600')
  }

  updateStyles() {
    this.inputTargets.forEach(input => {
      this.updateLabelStyle(input)
    })
  }
}
