import { Controller } from "stimulus"
import { gsap, Power2 } from "gsap"

export default class extends Controller {
  static targets = ["step1", "step2", "step3", "content1", "content2", "content3", "border1", "border2", "border3", "line1", "line2", "stepIndicator1", "stepIndicator2", "stepIndicator3", "templateStep1Completed", "templateStep2Ongoing", "templateStep2Completed", "templateStep3Ongoing"]

  connect() {
    this.currentStep = 1
    this.animationInProgress = false
  }

  nextStep() {
    if (this.animationInProgress) return
    this.animationInProgress = true

    if (this.currentStep === 1) {
      this.animateStep1to2()
    } else if (this.currentStep === 2) {
      this.animateStep2to3()
    } else if (this.currentStep === 3) {
      this.hideComponent()
    }
  }

  animateStep1to2() {
    gsap.set(this.step1Target, { width: "50%" });
    gsap.set(this.content1Target, { opacity: 1 });
    gsap.set(this.step2Target, { width: "25%" });
    gsap.set(this.content2Target, { opacity: 0 });

    gsap.timeline()
      .to(this.content1Target, {
        opacity: 0,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .call(() => this.updateStep1Content())
      .to(this.content1Target, {
        opacity: 1,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .to([this.step1Target, this.step2Target], {
        width: (index) => index === 0 ? "25%" : "50%",
        duration: 0.4,
        ease: Power2.easeInOut
      }, "-=0.15")
      .call(() => this.updateStep2Content())
      .to(this.content2Target, {
        opacity: 1,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .call(() => {
        this.currentStep = 2;
        this.animationInProgress = false;
      });
  }

  animateStep2to3() {
    gsap.set(this.step2Target, { width: "50%" });
    gsap.set(this.content2Target, { opacity: 1 });
    gsap.set(this.step3Target, { width: "25%" });
    gsap.set(this.content3Target, { opacity: 0 });

    gsap.timeline()
      .to(this.content2Target, {
        opacity: 0,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .call(() => this.updateStep2CompletedContent())
      .to(this.content2Target, {
        opacity: 1,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .to([this.step2Target, this.step3Target], {
        width: (index) => index === 0 ? "25%" : "50%",
        duration: 0.4,
        ease: Power2.easeInOut
      }, "-=0.15")
      .call(() => this.updateStep3Content())
      .to(this.content3Target, {
        opacity: 1,
        duration: 0.2,
        ease: Power2.easeInOut
      })
      .call(() => {
        this.currentStep = 3;
        this.animationInProgress = false;
      });
  }

  updateStep1Content() {
    const completedContent = this.templateStep1CompletedTarget.content.querySelector('[data-reward-steps-target="content1"]')
    if (completedContent) {
      this.content1Target.innerHTML = completedContent.innerHTML
    }
    this.border1Target.classList.remove('border-trade-primary')
    this.border1Target.classList.add('border-stroke')
    this.line1Target.classList.remove('bg-stroke')
    this.line1Target.classList.add('bg-trade-primary')
    // Keep text white for completed step
    this.stepIndicator1Target.classList.remove('text-gray-300')
    this.stepIndicator1Target.classList.add('text-black')
  }

  updateStep2Content() {
    const ongoingContent = this.templateStep2OngoingTarget.content.querySelector('[data-reward-steps-target="content2"]')
    if (ongoingContent) {
      this.content2Target.innerHTML = ongoingContent.innerHTML
    }
    this.border2Target.classList.remove('border-stroke')
    this.border2Target.classList.add('border-trade-primary')
    this.border2Target.style.background = 'rgb(17, 32, 32)'
    this.stepIndicator2Target.classList.remove('bg-stroke', 'text-gray-300')
    this.stepIndicator2Target.classList.add('bg-trade-primary', 'text-black')
  }

  updateStep2CompletedContent() {
    const completedContent = this.templateStep2CompletedTarget.content.querySelector('[data-reward-steps-target="content2"]')
    if (completedContent) {
      this.content2Target.innerHTML = completedContent.innerHTML
    }
    this.border2Target.classList.remove('border-trade-primary')
    this.border2Target.classList.add('border-stroke')
    this.border2Target.style.background = 'none'
    this.line2Target.classList.remove('bg-stroke')
    this.line2Target.classList.add('bg-trade-primary')
    // Keep text white for completed step
    this.stepIndicator2Target.classList.remove('text-gray-300')
    this.stepIndicator2Target.classList.add('text-black')
  }

  updateStep3Content() {
    const ongoingContent = this.templateStep3OngoingTarget.content.querySelector('[data-reward-steps-target="content3"]')
    if (ongoingContent) {
      this.content3Target.innerHTML = ongoingContent.innerHTML
    }
    this.border3Target.classList.remove('border-stroke')
    this.border3Target.classList.add('border-trade-primary')
    this.border3Target.style.background = 'rgb(17, 32, 32)'
    this.stepIndicator3Target.classList.remove('bg-stroke', 'text-gray-300')
    this.stepIndicator3Target.classList.add('bg-trade-primary', 'text-black')
  }

  hideComponent() {
    // Add overflow-hidden to prevent content from overlapping during hide animation
    this.element.style.overflow = 'hidden'

    gsap.to(this.element, {
      height: 0,
      opacity: 0,
      duration: 0.4,
      ease: Power2.easeInOut,
      onComplete: () => {
        this.element.style.display = "none"
        this.animationInProgress = false
        // Remove overflow-hidden after animation completes
        this.element.style.overflow = ''
      }
    })
  }
}
