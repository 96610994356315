import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap"

export default class extends Controller {
  static targets = ["content", "toggleButton", "toggleText", "toggleIcon"]

  connect() {
    this.isExpanded = false
    gsap.set(this.element, { height: "auto", overflow: "hidden" })
    this.collapsedHeight = this.element.offsetHeight
  }

  toggle() {
    if (this.isExpanded) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    this.element.style.overflow = "hidden"

    // Animate opacity of hidden paragraphs
    gsap.to(this.contentTargets.slice(1), {
      opacity: 1,
      duration: 0.3,
      stagger: 0.1,
      ease: "power2.out",
      onStart: () => {
        this.contentTargets.slice(1).forEach(el => {
          el.classList.remove("hidden")
          el.style.opacity = 0 // Set initial opacity
        })
      }
    })

    // Remove line-clamp-1 from the first <p> element
    this.contentTargets[0].classList.remove("line-clamp-1")

    gsap.to(this.toggleIconTarget, {
      rotation: 180,
      duration: 0.3,
    })

    this.toggleTextTarget.textContent = "Show less"

    gsap.fromTo(this.element,
      { height: this.collapsedHeight },
      {
        height: "auto",
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => {
          this.element.style.overflow = ""
        }
      }
    )

    this.isExpanded = true
  }

  collapse() {
    this.element.style.overflow = "hidden"

    // Animate opacity of paragraphs that will be hidden
    gsap.to(this.contentTargets.slice(1), {
      opacity: 0,
      duration: 0.2,
      stagger: 0.1,
      ease: "power2.in",
      onComplete: () => {
        this.contentTargets.slice(1).forEach(el => el.classList.add("hidden"))
      }
    })

    gsap.to(this.element, {
      height: this.collapsedHeight,
      duration: 0.3,
      ease: "power2.out",
      onComplete: () => {
        this.contentTargets[0].classList.add("line-clamp-1")
        this.element.style.overflow = ""
      }
    })

    gsap.to(this.toggleIconTarget, {
      rotation: 0,
      duration: 0.3,
    })

    this.toggleTextTarget.textContent = "Show more"

    this.isExpanded = false
  }
}
