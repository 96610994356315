import gsap from "gsap"

export class CheckmarkAnimation {
  constructor(element, options = {}) {
    this.element = element
    this.options = {
      color: 'var(--leaf-medium-hex)',
      duration: 1,
      autoplay: true,
      onComplete: () => {},
      onReverseComplete: () => {},
      ...options
    }

    this.setupStyles()
    this.createTimeline()

    if (this.options.autoplay) {
      this.play()
    }
  }

  setupStyles() {
    const { color } = this.options

    // Style the SVG element
    this.element.style.stroke = color
    this.element.style.strokeWidth = '2'
    this.element.style.strokeMiterlimit = '10'

    // Style the circle
    this.circle = this.element.querySelector('.checkmark__circle')
    this.circle.style.stroke = color
    this.circle.style.strokeWidth = '2'
    this.circle.style.fill = 'transparent' // Keep background transparent

    // Style the checkmark
    this.check = this.element.querySelector('.checkmark__check')
    this.check.style.stroke = color
    this.check.style.strokeWidth = '2'
    this.check.style.fill = 'none'
  }

  createTimeline() {
    this.tl = gsap.timeline({
      paused: !this.options.autoplay,
      onComplete: this.options.onComplete,
      onReverseComplete: this.options.onReverseComplete
    })

    // Circle drawing animation
    this.tl.fromTo(this.circle, {
      strokeDasharray: 166,
      strokeDashoffset: 166,
    }, {
      strokeDashoffset: 0,
      duration: this.options.duration * 0.4,
      ease: "power2.out"
    })

    // Checkmark drawing animation
    this.tl.fromTo(this.check, {
      strokeDasharray: 48,
      strokeDashoffset: 48
    }, {
      strokeDashoffset: 0,
      duration: this.options.duration * 0.3,
      ease: "power2.out"
    })

    // Subtle scale animation
    this.tl.to(this.element, {
      scale: 1.1,
      duration: this.options.duration * 0.15,
      ease: "power2.inOut"
    })
      .to(this.element, {
        scale: 1,
        duration: this.options.duration * 0.15,
        ease: "power2.inOut"
      })
  }

  play() {
    this.tl.play()
  }

  pause() {
    this.tl.pause()
  }

  reverse() {
    this.tl.reverse()
  }

  restart() {
    this.tl.restart()
  }

  seek(position) {
    this.tl.seek(position)
  }

  progress(value) {
    if (value === undefined) {
      return this.tl.progress()
    }
    this.tl.progress(value)
  }
}
